// We have deprecated emotion. Please use compiled instead
// eslint-disable-next-line no-restricted-imports, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';

import { token } from '@atlaskit/tokens';

type style = {
	style?: object;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const Image = styled.div(({ style }: style) => ({
	float: 'none',
	maxHeight: '200px',
	paddingTop: token('space.250', '20px'),
	backgroundRepeat: 'no-repeat',
	margin: 'auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	...style,
}));
