import gql from 'graphql-tag';

export const OverviewNotFoundSpaceQuery = gql`
	query OverviewNotFoundSpaceQuery($spaceKey: String) {
		space(key: $spaceKey) {
			id
			name
		}
	}
`;
