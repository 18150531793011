import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Root = styled.div({
	textAlign: 'center',
	height: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SubText = styled.p({
	margin: `${token('space.150', '12px')} 0 ${token('space.200', '16px')} 0`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ImageContainer = styled.div({
	display: 'block',
	minHeight: '165px',
	// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage/preview
	margin: '100px auto 0 auto',
	textAlign: 'center',
	float: 'none',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Text = styled.div({
	maxWidth: '400px',
	display: 'block',
	// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage/preview
	margin: '0 auto 150px',
	textAlign: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Content = styled.div({
	minHeight: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const MainText = styled.h6({
	textTransform: 'uppercase',
	color: token('color.text.subtlest', '#6b778c'),
	fontWeight: 'bold',
	lineHeight: 1.66666667,
});
