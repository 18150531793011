import PropTypes from 'prop-types';
import React from 'react';
import { styled } from '@compiled/react';
import { useQuery } from '@apollo/react-hooks';

// Components
import { FormattedMessage } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import AkButton from '@atlaskit/button/standard-button';

import { VIEW_CONTENT_EXPERIENCE, ExperienceSuccess } from '@confluence/experience-tracker';
import { useSessionData } from '@confluence/session-data';
import { getContextAwareFullPath } from '@confluence/route-manager/entry-points/getContextAwareFullPath';
import { PageLoadEnd } from '@confluence/browser-metrics';
import { BlankExperience } from '@confluence/blank-experience';

//query
import { OverviewNotFoundSpaceQuery } from './OverviewNotFoundSpaceQuery.graphql';

//perf metric
import { NOT_FOUND_PAGE_LOAD } from './perf.config';

// Helpers

const imageStyles = {
	width: 151,
	height: 153,
	backgroundImage: `url(${require('./icon-space-overview-empty.svg')})`,
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderContainer = styled.div({
	display: 'flex',
	padding: `${token('space.300', '24px')} ${token('space.500', '40px')} ${token(
		'space.400',
		'32px',
	)}`,
	marginBottom: token('space.negative.400', '-32px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& h1': {
		margin: 0,
		fontSize: '28px',
		fontWeight: 'normal',
		lineHeight: 1.25,
	},
});

export const OverviewNotFound = (props) => {
	const { spaceKey } = props;
	const { isLicensed } = useSessionData();
	const { data, loading, error } = useQuery(OverviewNotFoundSpaceQuery, {
		variables: {
			spaceKey,
			isLicensed,
		},
	});

	if (error || loading) {
		return null;
	}

	// preventing flicker
	if (!data || !data.space) {
		return null;
	}

	const editSpaceUrl = getContextAwareFullPath(`/spaces/editspace.action?key=${spaceKey}`, true);

	return (
		<div>
			<HeaderContainer>
				<h1 data-test-id="title-text" id="title-text">
					{data.space.name}
				</h1>
			</HeaderContainer>
			<div data-test-id="space-overview-blank-experience">
				<BlankExperience
					imageStyles={imageStyles}
					mainBanner={
						<FormattedMessage
							id="not-found.overview.blankExperience.mainBanner"
							defaultMessage="Well, this is a bit awkward."
							description="funny message to let user know we couldn't find their page"
						/>
					}
					subBanner={
						<FormattedMessage
							id="not-found.overview.blankExperience.subBanner"
							// TODO: replace straight quotes with curly quotes (see go/curlyquotes)
							// eslint-disable-next-line no-restricted-syntax
							defaultMessage="This space doesn't have a homepage so there's nothing to display here. Sad panda."
							description="funny message to let user know we couldn't find their page"
						/>
					}
					subBanner2={
						<FormattedMessage
							id="not-found.overview.blankExperience.subBanner2"
							defaultMessage="Add a homepage now to spruce things up, and use it to tell your visitors what this space is all about!"
							description="funny message to let user know we couldn't find their page"
						/>
					}
					callToAction={
						<AkButton appearance="primary" href={editSpaceUrl}>
							<FormattedMessage
								id="not-found.set.space.home"
								defaultMessage="Set space home"
								description="Button to create a new space homepage"
							/>
						</AkButton>
					}
				/>
				<PageLoadEnd metric={NOT_FOUND_PAGE_LOAD} customData={{ isBlankExperience: true }} />
				<ExperienceSuccess name={VIEW_CONTENT_EXPERIENCE} />
			</div>
		</div>
	);
};

OverviewNotFound.displayName = 'SpaceOverviewBlankExperience';

OverviewNotFound.propTypes = {
	/**
	 * Space Key string
	 */
	spaceKey: PropTypes.string.isRequired,
};
